<script>
import Layout from "@/router/layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import Swal from "sweetalert2";
import axios from "axios";
/**
 * Dashboard Component
 */
export default {
  page: {
    title: "Edit Tipe Surat Keluar",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
  },
  data() {
    return {
      title: "Edit Tipe Surat Keluar",
      items: [
        {
          text: "Master",
          href: "/",
        },
        {
          text: "Tipe Surat Keluar",
          href: "/master/Tipe Surat Keluar",
        },
        {
          text: "Edit Tipe Surat Keluar",
          active: true,
        },
      ],
      // Catch Error Axios
      axiosCatchError: null,
      // variable Page
      master_role: ["one", "two", "three"],
      role_selected: "",
      tsk_id: this.$route.params.tsk_id,
      nama: "",
      kode: "",
    };
  },
  mounted() {
    this.getTipeSuratKeluar();
  },
  methods: {
    resetForm() {
      this.nama = "";
      this.kode = "";
    },
    getTipeSuratKeluar() {
      Swal.fire({
        title: '<i class="fas fa-spinner fa-spin"></i>',
        text: "Loading...",
        showConfirmButton: false,
        allowOutsideClick: false,
      });

      let self = this;
      axios
        .get(
          process.env.VUE_APP_BACKEND_URL_VERSION +
            "/api/master/tipe_surat_keluar?id=" +
            self.tsk_id
        )
        .then((response) => {
          var response_data = response.data;
          var response_data_fix = response_data.list_data.data[0];
          if (response_data.code == 200) {
            self.nama = response_data_fix.nama;
            self.kode = response_data_fix.kode;
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: response_data.message,
            });
          }
          Swal.close();
        });
    },
    StoreData() {
      let self = this;
      Swal.fire({
        title: '<i class="fas fa-spinner fa-spin"></i>',
        text: "Loading...",
        showConfirmButton: false,
      });
      var FormData = require("form-data");
      var data = new FormData();
      data.append("id", self.tsk_id);
      data.append("nama", self.nama);
      data.append("kode", self.kode);

      var config = {
        method: "post",
        url:
          process.env.VUE_APP_BACKEND_URL_VERSION +
          "/api/master/tipe_surat_keluar/update",
        data: data,
      };
      axios(config)
        .then(function (response) {
          var response_data = response.data; 
          if(response_data.code != 200){
              Swal.fire({
              icon: "error",
              title: "Oops...",
              html: response_data.message,
              });
          }else{
            let timerInterval;
            Swal.fire({
              icon: "success",
              title: "Berhasil",
              text: "Anda akan diarahkan ke halaman master tipe surat keluar segera",
              timer: 2000,
              timerProgressBar: true,
              showCancelButton: false,
              showConfirmButton: false,
              willClose: () => {
                clearInterval(timerInterval);
              },
            }).then((result) => {
              /* Read more about handling dismissals below */
              if (result.dismiss === Swal.DismissReason.timer) {
                self.$router.push({ name: "all-tipe-surat-keluar" });
              }
            });
          }
        })
        .catch((e) => {
          this.axiosCatchError = e.response.data.data;
          Swal.close();
        });
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-8">
        <div class="card">
          <b-form class="p-2" @submit.prevent="StoreData">
            <div class="card-body">
              <div class="row">
                <div class="col-md-12">
                  <b-form-group
                    class="mb-3"
                    label="Nama"
                    label-for="formrow-nama-input"
                  >
                    <b-form-input
                      id="formrow-nama-input"
                      type="text"
                      v-model="nama"
                    ></b-form-input>
                  </b-form-group>
                  <b-form-group
                    class="mb-3"
                    label="Kode"
                    label-for="formrow-kode-input"
                  >
                    <b-form-input
                      id="formrow-kode-input"
                      type="text"
                      v-model="kode"
                    ></b-form-input>
                  </b-form-group>
                </div>
              </div>
            </div>
            <div class="card-footer">
              <b-button type="submit" variant="primary" class="m-1"
                ><i class="fa fa-save"></i> Simpan</b-button
              >
              <b-button type="reset" @click="resetForm" variant="danger" class="m-1"
                ><i class="fa fa-refresh"></i> Reset</b-button
              >
            </div>
          </b-form>
        </div>
      </div>
    </div>
  </Layout>
</template>
